import Themur from "themur/dist/themur.umd";

// Theme switcher
const theme = new Themur({
	toggleElement: document.getElementById("theme-switcher"),
	themeClass: "light-theme",
	useLocalStorage: true,
});
// Copyright year
document.getElementById("copy-year").innerHTML = new Date().getFullYear();
